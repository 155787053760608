import {
  BrowserOptions, browserTracingIntegration, captureConsoleIntegration, contextLinesIntegration, ErrorEvent,
  extraErrorDataIntegration
} from '@sentry/react';

const IGNORED_ERRORS = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  'Can\'t find variable: ZiteReader',
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  'tinymce',
  /tinymce/,
  'for=',
  // tinymce related errors
  /tinymce-4\.5\.2/,
  // Error generated by a bug in auto-fill library from browser
  // https://github.com/getsentry/sentry/issues/5267
  /Blocked a frame with origin/,
  // Below error is basically the browser complaining that
  // ResizeObserver wasn't able to complete its work within an animation frame.
  // See: https://stackoverflow.com/questions/49384120
  'ResizeObserver loop limit exceeded',
  // This error comes from the EDGE and IE users that uses Visual basic
  // to connect to jotform, when the connection fails, we receive below error on sentry
  'The remote server machine does not exist or is unavailable',
  // Some firefox versions on windows devices throws below error
  // possibly thrown from an firefox add-on
  'can\'t redefine non-configurable property "userAgent"',
  'SVGAnimatedString',
  'You have entered your current password wrong.',
  'New password should have minimum 8 characters.',
  'Username is not available.',
  'Link is already taken.',
  'URL Slug may only contain letters or combination of letters, numbers, dash and underscores ("_-”).',
  /^.*(email address is already registered!).*$/gim,
  /^.*(You're not authorized to use).*$/gim,
  /^.*(hooks).*$/gim,
  /^.*(username).*$/gim,
  /^.*(password).*$/gim,
  /^.*(byte).*$/gim,
  'zoid destroyed all components',
  '[GSI_LOGGER]:', // Google Auth Errors
  '[olark]', // olark chatbot errors
  '[Meta Pixel]' // pixel initialization errors
];

function findValue(event: ErrorEvent, search: RegExp) {
  if (!event.exception || !event.exception.values) {
    return false;
  }

  let found = false;
  event.exception.values.forEach(value => {
    if (!value.value) {
      return false;
    }
    found = search.test(value.value);
    return !found;
  });
  return found;
}

/**
 * Determines if a string or regex is included in another string
 * @param {string} str string to search
 * @param {string | RegExp} search string or regex to search for
 * @returns {boolean} true if search is found in str
 */
function includes(str: string, search: string | RegExp): boolean {
  if (typeof search === 'string') {
    return str.includes(search);
  }
  return search.test(str);
}

export const defaultSentryConfigs: BrowserOptions = {
  integrations: [
    browserTracingIntegration({
      traceFetch: false,
      traceXHR: false
    }),
    captureConsoleIntegration({ levels: ['error'] }),
    extraErrorDataIntegration(),
    contextLinesIntegration()
  ],
  attachStacktrace: true,
  sampleRate: 0.1,
  tracesSampler: ({ attributes }) => {
    // send pageload and navigation traces with 0.1% probability
    if (['pageload', 'navigation'].includes(attributes?.['sentry.op'] as string)) {
      return 0.001;
    }
    // default trace rate
    return 0.1;
  },
  debug: false,
  environment: 'production',
  allowUrls: [
    /https?:\/\/.*jotform\.com/,
    /https?:\/\/cdn([0-9]*)\.jotfor\.ms/,
    /https?:\/\/.*jotform\.pro/
  ],
  normalizeDepth: 50,
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /tinymce/i,
    /^.*(https:\/\/api.jotform.com\/translationList).*$/gim,
    /^.*(pagead\/viewthroughconversion).*$/gim
  ],
  ignoreErrors: IGNORED_ERRORS,
  // eslint-disable-next-line complexity, max-statements
  beforeSend: (event, hint) => {
    if (/Android 4\.4\.[2,4]/.test(window.navigator.userAgent)) {
      return null;
    }

    // Non error promise rejection events
    if (/Non-Error promise rejection/i.test(event?.message || '')) {
      return null;
    }

    // tinymce errors
    if (/cloneNode/i.test(event?.message || '')) {
      return null;
    }

    if (/t is null/i.test(event?.message || '')) {
      return null;
    }

    if ((event.message && /"Invariant Violation"/.test(event.message)) || findValue(event, /"Invariant Violation"/)) {
      return null;
    }

    // Ignore FS errors
    if (findValue(event, /"Timeout \(h\)"/)) {
      return null;
    }

    if (findValue(event, /"Timeout"/)) {
      return null;
    }

    if (findValue(event, /"Unable to find node on an unmounted component."/)) {
      return null;
    }

    const error = hint?.originalException as {message: string};
    if (error?.message) {
      const { message } = error;
      const exactErrors = [
        'chrome is not defined', // Firefox extension tries to use chrome object
        'tm_x is not defined', // Chrome Mobile WebView 99, Android 9;
        'twttr is not defined', // Twitter GTM tag errors
        "Can't find variable: $",
        "Can't find variable: al_onPoststitialDismiss", // 3rd party, Mobile Safari UI/WKWebView, iOS 15.3.1
        "Can't find variable: article",
        "Can't find variable: twttr", // Twitter GTM tag errors
        "Can't find variable: WeixinJSBridge", // 3rd party, Mobile Safari UI/WKWebView, iOS 13.6
        "Identifier 'boundExec' has already been declared", // 3rd party, Windows 10
        "Identifier 'kImgMinWidth' has already been declared", // Chrome Mobile WebView 87, Android 11
        "Unexpected token 'var'"
      ];

      const partialErrors = [
        '__firefox__', // Firefox internal errors
        '__show__deepen', // noise likely coming from browsers/extensions
        'ats.js', // ATS tracker script (probably coming from GTM)
        'BetterJsPop', // adblocker
        'C3 is not defined', // coming from C3 maps script
        'checkDomStatus', // likely coming from ads/campaigns
        'chrome-extension',
        'Could not find googletag', // Another possible GTM issue
        'Could not find Nativo', // Nativo third-party script
        'crazyegg',
        'crypto.getRandomValues', // third-party app
        'customDispatchEvent is not defined', // Issue coming from crazyegg third-party script
        'DomNode has not been set for this SimpleScriptable', // HTMLUnit issue coming from very old browsers
        'DOMNodeInsertedByJs', // Coming from an Android browser called Crosswalk
        'fb.js', // Issues coming from Facebook pixel/third-party script on GTM
        'First argument to Readability constructor should be a document object', // Readability app on Mobile devices
        'fixedTimeID is not defined', // Likely coming from a browser extension
        'getHiAnalysticsParam', // Coming from Huawei mobile devices
        'grecaptcha is not defined', // Coming from recaptcha
        'instantSearchSDKJSBridgeClearHighlight', // Bing Instant Search issue with Edge on iOS
        'Java exception was raised', // Android internal issues
        'keydownt', // Coming from a chrome extension
        'langDetector is not defined', // Translation third-party script
        'localStorage', // mobile device settings
        'MoatMAK', // Issues coming from Mobile ads (MRAID SDK)
        'mobincube', // third-party app
        'moz-extension', // Firefox
        'mraid', // Issues coming from Mobile ads (MRAID SDK)
        'NetworkError: Load failed', // Fetch issue on user browser
        'NotAllowedError: Read permission denied', // User browser issue originated from third-party scripts
        'notifyCurrentVideoState is not defined', // Coming from Mobile devices
        'NS_ERROR_', // Firefox internal errors
        'Object Not Found Matching Id:', // Microsoft Outlook SafeLink crawlers
        'onAPWebViewPause', // Issue coming from Webviews on Mobile devices
        'onTabScrolled is not defined', // Might be coming from browser extensions or third-party apps
        'perfectbrowsr_nodecheck', // browser extension
        'pktAnnotationHighlighter', // Issues coming from Pocket on iOS
        'prebidjs', // Coming from a bidding external script (Prebid.js)
        'privateSpecialRepair', // likely coming from ads/campaigns
        'QK_middlewareReadModePage', // noise from ads/campaigns
        'Quora Pixel Error',
        'redefine property: googletag', // GTM
        'safari-extension',
        'safari-web-extension',
        'safari.self.tab.setContext', // Safari browser error
        'SecurityError: Blocked a frame with origin', // CORS issues from users or third-party scripts/apps
        'sendBeacon', // Issues coming from Cloudflare beacon.min.js script
        'setIOSParameters', // Mobile Safari browser issue on iOS
        'Taboola', // Taboola third-party script
        'tgetT is not defined', // 3rd party
        'TypeError: WebKit', // Safari (WebKit) internal issues
        'ucapi is not defined', // Might be coming from browser extensions or third-party apps
        'UCShellJava', // noise coming from UC Browser
        'UXCam', // third-party app
        'vid_mate_check', // third-party app
        'VUE_DEVTOOLS_IFRAME', // Issue coming from Vue Devtools extension
        'webkitExitFullScreen', // Mobile Safari browser issue on iOS
        'window.appHotStart', // Might be coming from browser extensions or third-party apps
        'window.bannerNight', // Might be coming from browser extensions or third-party apps
        'window.getPlayer is not a function', // Third-party scripts
        'window.huawei', // Coming from Huawei mobile devices
        'window.onunload is not a function', // Third-party scripts
        'window.performance.getEntries is not a function', // Trying to access from console in unsupported browser
        'window.regainData is not a function', // Third-party scripts
        'window.showAngularStats', // Might be coming from browser extensions or third-party apps
        'zaloJSV2', // Zalo API
        'Zotero', // third-party app
        "Can't find variable: al_", // Appears only on Apple devices, related to tracking
        "Failed to read the 'cookie' property from 'Document'", // User browser issue to fetch cookies
        "getElementsByTagName('video')", // Mobile Safari browser issue on iOS
        "Identifier 'VIDEO_PLAYING'", // noise likely coming from browsers/extensions
        'GSI_LOGGER', // Google auth noise
        'ReferenceError: UET is not defined', // happens when bing is blocked by adblockers
        '[olark]', // olark chatbot errors
        '[Meta Pixel]' // pixel initialization errors
      ];

      const patterns = [
        /window.cb\d+ is not a function/ // 3rd party, Windows 10, Edge 98.0.1108
      ];

      if (
        exactErrors.includes(message)
        || partialErrors.some(partialError => message.includes(partialError))
        || patterns.some(pattern => pattern.test(message))
      ) {
        return null;
      }

      const parsedEvent = JSON.stringify(event);

      const match = [
        '/Users/', // MacOS localhost
        'adblocker', // Ad blockers
        'addons/pops/license', // Coming from random addons
        'ads/pixel.js', // Reddit Pixel
        'ats.js', // ATS tracker script (probably coming from GTM)
        'bad-network-popup', // third-party script
        'beacon.min.js', // Cloudflare script
        'beforeLoad_SafariV2.js', // Coming from Safari extensions
        'BVProxy.min.js', // BVProxy external script
        'C:\\Users\\', // Windows localhost
        'card-injection.js', // coming from a netfree.link website, might be an extension or app
        'chrome-extension',
        'clarity.js', // Microsoft Clarity analytics tool
        'common-scripts', // Third-party scripts such as CrazyEgg
        'crypto.getRandomValues', // third-party app
        'fb.js', // Issues coming from Facebook pixel/third-party script on GTM
        'Grammarly.js', // Grammarly extension
        'gtm.js', // GTM main script
        'hstc.tracking', // Hubspot tracking script
        'localStorage', // Mobile device settings (usually coming from Android)
        'MathJax.js', // MathJax.js script
        'moz-extension', // Firefox
        'nrWrapper', // NewRelic
        'processRandomSelector', // noise from ads/campaigns
        'r.beacon', // NewRelic
        'recaptcha/releases',
        'RILLONGPRESS', // third-party script
        'safari-extension',
        'safari-web-extension',
        'setupPops', // Chrome issue coming from adblockers
        'setupPops', // Chrome issue coming from adblockers
        'SnapTube', // External app to download videos
        'srcIsLegacy', // Drift third-party script
        'translate_http', // Issues coming from Google Translate API
        'uwt.js', // Twitter Universal Tag
        'zteEnd' // Coming from an external script related to ZTE phones
      ].some(predicate => parsedEvent.includes(predicate));

      if (match) {
        return null;
      }
    }

    const values = event?.exception?.values;
    if (values?.length) {
      const [{
        type, value, stacktrace
      }] = values;

      if (type === 'UnhandledRejection') {
        // Google reCAPTCHA timeout exceptions
        const recaptchaPattern = /^Non-Error promise rejection captured with value: Timeout( \(\w\))?$/;
        if (recaptchaPattern.test(value || '')) {
          return null;
        }
        if (!stacktrace && value === 'Non-Error promise rejection captured with keys: errors, message, name') {
          const extra = event?.extra;
          if (extra) {
            const message = (extra.__serialized__ as {message: string})?.message;

            // 3rd party code RxJS error, supposedly Grammarly extension
            if (message === '1 errors occurred during unsubscription:\n1) RangeError: Maximum call stack size exceeded.') {
              return null;
            }
          }
        }
        // Do not send errors like "Non-Error promise rejection captured with value: content, keys, name"
        // as these are handled in some other/hacky way in the code
        if (value && (value.includes('Object captured as promise rejection with keys:') || value.includes('Non-Error promise rejection captured with keys:'))) {
          if (value.includes(',')) {
            return null;
          }
        }
      }
    }

    if (event?.extra?.arguments) {
      const parsedArguments = JSON.stringify(event.extra.arguments);
      if (IGNORED_ERRORS.some(ignored => includes(parsedArguments, ignored))) {
        return null;
      }
    }

    if (event?.breadcrumbs instanceof Array && event?.breadcrumbs.length > 0) {
      const fullStoryBreadcrumbs = event.breadcrumbs.filter(breadcrumb => breadcrumb.category === 'xhr' && breadcrumb.data?.url?.includes('https://rs.fullstory.com/rec/bundle/v2?OrgId=K1ZZ'));
      if (fullStoryBreadcrumbs.length > 0) {
        if (event?.tags) {
          // eslint-disable-next-line no-param-reassign
          event.tags.withFullStory = true;
        }
      } else if (event?.tags) {
        // eslint-disable-next-line no-param-reassign
        event.tags.withFullStory = false;
      }

      if (event.exception?.values?.map(e => e.value).includes('Event `CustomEvent` (type=unhandledrejection) captured as promise rejection')) {
        // Events with <unknown> title generally happens after a XHR call to the following sites.
        // If one of these is the last call made, ignore the error.
        const XHRS_TO_IGNORE = [
          /ads\.linkedin/,
          /clarity/,
          /events\.jotform/,
          /google-analytics/
        ];
        const lastFailedNetworkCall = event.breadcrumbs.slice().reverse().find(crumb => ((crumb.type === 'http') && crumb?.data?.status_code !== 200));
        if (lastFailedNetworkCall) {
          if (XHRS_TO_IGNORE.some(ignored => (ignored.test(lastFailedNetworkCall?.data?.url)))) {
            return null;
          }
        }
      }
    }

    return event;
  }
};

export const SENTRY_ORG_SLUG = 'jotform';
