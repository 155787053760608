/* eslint-disable max-lines,react/sort-comp */
/* global HTMLElement */
import React from 'react';
import {
  shape,
  bool,
  string,
  func,
  oneOf,
  node,
  arrayOf,
  number,
  element,
  elementType,
  oneOfType,
  object
} from 'prop-types';
import ReactDOM from 'react-dom';
import Axios from 'axios';
import qs from 'querystring';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { Dialog } from '@jotforminc/dialog';
import { Portal } from '@jotforminc/uikit';
import { Texts } from '@jotforminc/constants';
import {
  createFormData,
  loadJS,
  getPossibleTimeZone,
  initGoogleSignin,
  initFBSignin,
  initAppleSignin,
  initMicrosoftSignin,
  initSalesforceSignin,
  randomString,
  getUrlParameter,
  classNames,
  handleCustomNavigation,
  resizeWindow
} from '@jotforminc/utils';
import { t } from '@jotforminc/translation';
import { isEnterprise } from '@jotforminc/enterprise-utils';

import SignupOptions from './SignupOptions';
import SignupWithEmail from './SignupWithEmail';
import LoginOptions from './LoginOptions';
import SSOOptions from './SSOOptions';
import ForgotPassword from './ForgotPassword';
import TwoFactorLogin from './TwoFactorLogin';
import EmailAuthLogin from './EmailAuthLogin';
import TwoFactorMethods from './TwoFactorMethods';
import SocialInit from './SocialInit';
import LoginError from './LoginError';
import DeleteAccountModal from './DeleteAccountModal';
import CloseWithCircleSVG from '../temporary-duplicate/assets/svg/closeWithCircle.svg';
import ResetSuccess from './ResetSuccess';
import '../styles/style.scss';
import '../temporary-duplicate/styles/SaveAndContinueLater/style.scss';
import {
  forceUserSocialLogin,
  handleLoginHelper,
  getScreenResolution,
  handleSignupCompletedEvents,
  createGoogleOneTapContainerEl,
  hideGoogleOneTapContainerEl
} from '../utils/loginSignup';
import SocialTermsStep from './SocialTermsStep';
import renderStandaloneSocialTermsModal from '../utils/renderStandaloneSocialTermsModal';
import renderStandaloneEmailAuthModal from '../utils/renderStandaloneEmailAuthModal';
import { preLoginCheck, verifyAndDelete, verifyUser } from '../utils/helper';
import VerifyAccountModal from './VerifyAccountModal';
import { OFACModal, openOFACModal } from './OFACModal';
import UnusualSignup from './UnusualSignup';
import SignupWithSocialOpts from './SignupWithSocialOpts';
import RecoverAccountPage from './RecoverAccountPage';
import RecoverAccountNotification from './RecoverAccountNotification';
import AccountRecoveryViewTicket from './AccountRecoveryViewTicket';
import {
  send2FAAccountRecoveryEmail,
  getUserSSOStatus
} from '../api';

export default class LoginFlow extends React.Component {
  constructor(props) {
    super(props);

    const {
      user, initialScreen, forceLogin, enableGoogleOneTap, mode
    } = props;

    this.isLoggedIn = !!user;

    this.state = {
      activeScreenKey: forceLogin ? 'loginOptions' : initialScreen,
      thereSocialError: false,
      lastLoginCredentials: { username: '', password: '' },
      lastTFAResponse: {},
      lastEAResponse: {},
      tfaMethod: '',
      lastSocialLoginToken: '',
      lastSocialLoginType: '',
      isSocialTFA: false,
      isSocialEA: false, // social email authentication
      thereErrorMessage: {},
      showThankyouModal: true,
      socialUserProfile: {},
      socialLoginVerificationHash: '',
      // SignupOptions social buttons
      isMsSignupTestEnabled: false,
      isMsSignupControlVariant: false, // Enables facebook option
      isMsSignupTestBVariant: true, // Enables microsoft option
      isMsSignupTestCVariant: false, // Enables both microsoft & facebook option
      isSwsoTestVariant: false,
      areSocialButtonsLoading: false,
      idToken: '',
      mode: mode,
      externalErrorMessage: '',
      tfaAccountRecoveryProps: {
        errorMessage: '', email: '', isErrored: false, isLoading: false
      }
    };

    this.activeComponentRef = React.createRef(null); // either delete modal or verify modal ref
    this.portalRef = React.createRef(null);
    this.ofacModalRef = React.createRef();
    this.handleSocialTFAResponse = this.handleSocialTFAResponse.bind(this);
    this.handleSocialEAResponse = this.handleSocialEAResponse.bind(this);
    this.handleTFAResponse = this.handleTFAResponse.bind(this);
    this.handleTFALogin = this.handleTFALogin.bind(this);
    this.handleEALogin = this.handleEALogin.bind(this);
    this.handleAppleSuccess = this.handleAppleSuccess.bind(this);
    this.handleDeleteAccount = this.handleDeleteAccount.bind(this);
    this.handle2FASocialLoginRequest = this.handle2FASocialLoginRequest.bind(this);
    this.nonce = randomString(32);
    loadJS('facebook-jssdk', 'https://connect.facebook.net/en_US/sdk.js');
    loadJS('google-jssdk-new', 'https://accounts.google.com/gsi/client');
    loadJS('apple-jssdk', 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js');
    loadJS('microsoft-jssdk', 'https://cdn.jotfor.ms/js/msal/msal-browser.js');

    if (enableGoogleOneTap) loadJS('googleOneTap', 'https://accounts.google.com/gsi/client', this.initializeGoogleOneTap);

    this.checkQueryParamsForNavigationChange();

    // accountSharing A/B Test
    this.initAccountSharingActions();
  }

  initActionRecorder = () => {
    window.actionRecorder = window.JotFormActions('LoginFlow');
  };

  loadJotformActions = () => {
    if (window.JotFormActions) {
      this.initActionRecorder();
      return;
    }
    const s = global.document.createElement('script');
    s.onload = () => {
      this.initActionRecorder();
    };
    s.src = '//js.jotform.com/actions.js';
    global.document.head.appendChild(s);
  };

  // A/B Test: microsoftSignup
  componentDidMount() {
    this.loadJotformActions();
    const {
      showUnusualSignupFlow, unusualSignupCode, unusualSignupToken, initialScreen, swsoTestVariant, registrationType
    } = this.props;

    if (showUnusualSignupFlow && !isEmpty(unusualSignupCode) && !isEmpty(unusualSignupToken)) {
      this.setState({
        mode: 'unusual-signup'
      });
    }

    if (initialScreen === 'signupWithSocialOpts' || swsoTestVariant) {
      this.setState({
        isSwsoTestVariant: true
      });
    }

    if (isEnterprise()) {
      this.handlePreLoginCheck();
    }

    if (registrationType === 'oauth') {
      window.addEventListener('resizeWindow', resizeWindow);
    }
  }

  componentWillUnmount() {
    const { registrationType } = this.props;
    if (this.clearError) {
      clearTimeout(this.clearError);
    }

    if (registrationType === 'oauth') {
      window.removeEventListener('resizeWindow');
    }
  }

  // Handle Pre Login Check Function
  handlePreLoginCheck = async () => {
    try {
      const result = await preLoginCheck();
      if (result?.preLogin) {
        const cleanResult = omit(result, ['preLogin']);
        const { primaryMethod = '' } = cleanResult;
        this.setState({
          lastTFAResponse: cleanResult,
          isSocialTFA: false,
          tfaMethod: primaryMethod
        });
        this.handleNavigationChangeRequest('twoFactorLogin');
      }
    } catch (error) {
      this.setState({
        externalErrorMessage: error.message
      });
    }
  };

  setExternalErrorMessage = message => {
    this.setState({
      externalErrorMessage: message
    });
  };

  // todo: remove the method after the test ends
  initAccountSharingActions = () => {
    if (window.JotFormActions && !window.__accountSharingActions) {
      window.__accountSharingActions = window.JotFormActions('accountSharing');
    }
    window.__accountSharingLogger = ({ action, target }) => {
      if (window.JOTFORM_ENV !== 'PRODUCTION') return;
      const { user: userProp } = this.props;
      const actor = userProp?.username || window.user?.username;
      window.__accountSharingActions?.tick({ actor, action, target });
    };
  };

  checkQueryParamsForNavigationChange = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const view = queryParams.get('view');

    if (view === 'forgotPassword') {
      this.handleNavigationChangeRequest('forgotPassword');
    }
  };

  handleGoogleOnetapResponse = googleUser => {
    const token = googleUser.credential;
    const { socialUserProfile: currentProfile } = this.state;
    this.setState({
      socialUserProfile: {
        token,
        ...currentProfile,
        type: 'googleOneTap'
      },
      socialLoginVerificationHash: ''
    }, () => this.socialLoginRequest('googleOneTap', token, undefined, undefined, true));
  };

  initializeGoogleOneTap = () => {
    if (typeof window.GOOGLE_SIGNON === 'undefined') {
      return true;
    }

    if (typeof window.JOTFORM_ENV === 'undefined' || window.JOTFORM_ENV === 'ENTERPRISE' || window.JOTFORM_ENV === 'TESTING' || window.JOTFORM_ENV === 'DEVELOPMENT') {
      return true;
    }

    if (window.userData && window.userData.account_type && window.userData.account_type.name !== 'GUEST') {
      return true;
    }

    if (document.querySelector('.removeAuthButtons')) {
      return true;
    }

    createGoogleOneTapContainerEl();

    window.google.accounts.id.initialize({
      client_id: window.GOOGLE_SIGNON,
      cancel_on_tap_outside: false,
      prompt_parent_id: 'oneTapLoginArea',
      callback: this.handleGoogleOnetapResponse,
      state_cookie_domain: 'jotform.com'
    });
    window.google.accounts.id.prompt(notification => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        console.log('GoogleOnetap Skipped', notification.getNotDisplayedReason());
      }
    });
  };

  handleSocialTermsRequired = ({ socialLoginHash, socialUserProfile, idToken }) => {
    const { socialUserProfile: currentProfile } = this.state;
    this.setState({
      socialLoginVerificationHash: socialLoginHash,
      socialUserProfile: { ...currentProfile, ...socialUserProfile },
      idToken
    }, () => this.showTermsStep());
  };

  handleSocialTermsModalClose = () => {
    this.setState({
      socialLoginVerificationHash: '',
      socialUserProfile: {}
    });
  };

  showTermsStep = () => {
    const { user, showSocialTermsStepAsModal } = this.props;
    const { socialUserProfile, socialUserProfile: { type, isFormUser = false } } = this.state;
    hideGoogleOneTapContainerEl();
    if (showSocialTermsStepAsModal || type === 'googleOneTap' || isFormUser) {
      renderStandaloneSocialTermsModal({
        user,
        socialUserProfile,
        onSocialTermsContinue: this.handleSocialTermsContinue,
        onSocialTermsClose: this.handleSocialTermsModalClose
      });
    } else {
      this.handleNavigationChangeRequest('socialTermsStep');
    }
  };

  handleSocialTermsContinue = isTermsAgreed => {
    const { socialUserProfile: { type, token } } = this.state;
    if (isTermsAgreed) {
      this.socialLoginRequest(type, token);
    }
  };

  /**
   * Fires when the user suceeds logging in through a single sign on
   * using SAML.
   * @param {object} response response holding user data.
   */
  handleSSOLoginSuccess = response => {
    preLoginCheck().then(preLoginResponse => {
      const { preLogin: hasMFA, primaryMethod = '' } = preLoginResponse;
      if (hasMFA) {
        // If user has multi factor authantication, proceed to that.
        this.setState({
          lastTFAResponse: preLoginResponse,
          isSocialTFA: false,
          tfaMethod: primaryMethod
        });
        this.handleNavigationChangeRequest('twoFactorLogin');
      } else {
        this.handleLoginSuccess(response);
      }
    });
  };

  handleSSOLoginFail = message => {
    // TODO: request UI text review and add translation
    const errorMessage = message ?? 'An error occured in single sign on process';
    this.setExternalErrorMessage(errorMessage);
  };

  handleAppleSuccess = ({
    user: {
      email,
      name: {
        firstName = '',
        lastName = ''
      } = {}
    } = {},
    authorization: {
      code,
      id_token: idToken
    }
  }) => {
    const {
      actionLogger, apiURL, appName, buttonNames
    } = this.props;
    if (actionLogger) {
      actionLogger({
        target: 'appleLogin'
      });
    }

    const defaultButtonName = appName ? `apple-${appName}` : '';
    const buttonName = buttonNames.apple ? buttonNames.apple : defaultButtonName;

    const { isSocialLoginForDeleteMyAccountFlow, isSocialLoginForVerification } = this.props;

    if (isSocialLoginForDeleteMyAccountFlow || isSocialLoginForVerification) {
      const token = JSON.stringify({ authorizationCode: code, identityToken: idToken, realNonce: this.nonce });
      const verifierFunction = isSocialLoginForDeleteMyAccountFlow ? this.handleDeleteAccount : this.handleVerifyAccount;
      verifierFunction('apple', token);
      return;
    }

    Axios.post(`${apiURL}/login/apple`, qs.stringify({
      authorizationCode: code,
      nonce: this.nonce,
      identityToken: idToken,
      name: `${firstName} ${lastName}`,
      email,
      language: global.navigator.language,
      timezone: getPossibleTimeZone(),
      screen_resolution: getScreenResolution(),
      location: global.location.pathname,
      button_name: buttonName,
      ref_app: appName
    }), {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(response => {
        const {
          data: {
            responseCode, success, message, content = {}, duration
          }
        } = response;
        const isLogEnabled = getUrlParameter('logAppleLogin');

        if (isLogEnabled === '1') {
          console.log(response);
        }

        if (!success || responseCode !== 200) {
          throw new Error(message);
        }
        if (content?.isNewUser) {
          handleSignupCompletedEvents(undefined, 'apple');
        }
        const { primaryMethod = '' } = content;

        if (primaryMethod) {
          this.handle2FASocialLoginRequest({ ...content, duration: duration, success: success });
          return;
        }
        this.handleLoginSuccess(response);
      })
      .catch(error => {
        const { response: { data: { error: message = '' } = {} } = {}, message: errorMsg = '' } = error;
        if (message === "Sorry, you can't signup from this country." || errorMsg === "Sorry, you can't signup from this country.") {
          openOFACModal(this.ofacModalRef, email);
        }

        if (message === 'Your account has been suspended.' || errorMsg === 'Your account has been suspended.') {
          return handleCustomNavigation('/account-suspended', '_self', true);
        }

        console.error('--Couldn\'t login', error);
        this.handleSocialErrorChange(error);
      });
  };

  handleGoogleSuccess = googleUser => {
    const { code: token = '' } = googleUser || {};
    const { actionLogger } = this.props;
    const { socialUserProfile: currentProfile } = this.state;
    if (googleUser && token) {
      if (actionLogger) {
        actionLogger({
          target: 'googleLogin'
        });
      }
      this.setState({
        socialUserProfile: {
          token,
          type: 'google',
          ...currentProfile
        },
        socialLoginVerificationHash: ''
      }, () => this.socialLoginRequest('google', token));
    } else {
      this.handleSocialErrorChange(true);
    }
  };

  handleFacebookSuccess = ({ status, authResponse }) => {
    const { socialUserProfile: currentProfile } = this.state;
    const { accessToken: token = '' } = authResponse || {};
    if (status === 'connected' && authResponse && token) {
      const { actionLogger } = this.props;
      if (actionLogger) {
        actionLogger({
          target: 'facebookLogin'
        });
      }
      this.setState({
        socialUserProfile: {
          token,
          type: 'facebook',
          ...currentProfile
        },
        socialLoginVerificationHash: ''
      }, () => this.socialLoginRequest('facebook', token));
    } else {
      this.handleSocialErrorChange(true);
    }
  };

  handleMicrosoftSuccess = authResponse => {
    const { socialUserProfile: currentProfile } = this.state;
    const { accessToken = '', idToken = '' } = authResponse || {};
    if (authResponse && accessToken && idToken) {
      const multipleTokens = JSON.stringify({ idToken, accessToken });
      const { actionLogger } = this.props;
      if (actionLogger) {
        actionLogger({
          target: 'microsoftLogin'
        });
      }
      this.setState({
        socialUserProfile: {
          token: multipleTokens,
          type: 'microsoft',
          ...currentProfile
        },
        socialLoginVerificationHash: ''
      }, () => this.socialLoginRequest('microsoft', multipleTokens));
    } else {
      this.handleSocialErrorChange(true);
    }
  };

  handleSalesforceSuccess = authResponse => {
    const { socialUserProfile: currentProfile } = this.state;
    const { code = '', token = '' } = authResponse || {};
    if (authResponse && code && token) {
      const multipleTokens = JSON.stringify({ code, token });
      const { actionLogger } = this.props;
      if (actionLogger) {
        actionLogger({
          target: 'salesforceLogin'
        });
      }
      this.setState({
        socialUserProfile: {
          token: multipleTokens,
          type: 'salesforce',
          ...currentProfile
        },
        socialLoginVerificationHash: ''
      }, () => this.socialLoginRequest('salesforce', multipleTokens));
    } else {
      this.handleSocialErrorChange(true);
    }
  };

  handleGoogleFail = error => {
    this.handleSocialErrorChange(true);
    // eslint-disable-next-line no-console
    console.log('failure', error);
  };

  handleDeleteAccount = async (type, token) => {
    const { user } = this.props;

    const { credentials: { username } } = user;
    // All providers have their own error state management that's why there are nested control blocks
    try {
      const result = await verifyAndDelete({
        type, token, username
      });
      if (result === true || (typeof result === 'object' && result.token)) {
        this.activeComponentRef.current.handleStep(3);
      } else {
        this.setState({
          thereSocialError: true
        });
      }
    } catch (e) {
      this.setState({
        thereSocialError: true
      });
    }
  };

  handleVerifyAccount = async (type, token) => {
    const { user } = this.props;

    const { credentials: { username } } = user;
    // All providers have their own error state management that's why there are nested control blocks
    try {
      const result = await verifyUser({
        type, token, username
      });
      const isResultWithToken = (typeof result === 'object') && result.token;
      if (result === true || isResultWithToken) {
        this.activeComponentRef.current.approveVerification();
        // TODO if (isResultWithToken && isSocialLoginForVerification) {
        //   set token
        // }
      } else {
        this.setState({
          thereSocialError: true
        });
      }
    } catch (e) {
      this.setState({
        thereSocialError: true
      });
    }
  };

  handle2FASocialLoginRequest = response => {
    const { primaryMethod = '' } = response;
    this.setState({
      lastTFAResponse: response,
      isSocialTFA: false,
      tfaMethod: primaryMethod
    });
    this.handleNavigationChangeRequest('twoFactorLogin');
  };

  socialLoginRequest = async (type, token, tfaVerificationCode, tfaMethod, eaVerificationCode) => {
    const { idToken } = this.state;
    this.setState({ lastSocialLoginType: type, lastSocialLoginToken: token });
    const {
      apiURL, forceUser, isSocialLoginForDeleteMyAccountFlow, appName, buttonNames, isSocialLoginForVerification, initiator
    } = this.props;

    if (isSocialLoginForDeleteMyAccountFlow || isSocialLoginForVerification) {
      const verifierFunction = isSocialLoginForDeleteMyAccountFlow ? this.handleDeleteAccount : this.handleVerifyAccount;
      verifierFunction(type, token);
      return;
    }
    const newGoogleFlow = type === 'google' ? 1 : 0;

    const { socialLoginVerificationHash } = this.state;
    const enableSocialTerms = true;
    const authToken = idToken || token;
    if (forceUser) {
      // continue via request-server
      // since the api does not have
      // the functionality of claimGuestAccount
      forceUserSocialLogin(
        type,
        authToken,
        this.handleLoginSuccess,
        this.handleSocialErrorChange,
        appName,
        this.handleSocialTFAResponse,
        tfaVerificationCode,
        tfaMethod,
        buttonNames,
        socialLoginVerificationHash,
        this.handleSocialTermsRequired,
        enableSocialTerms,
        this.handleSocialEAResponse,
        eaVerificationCode,
        newGoogleFlow,
        this.ofacModalRef
      );
      return;
    }

    const isGoogle = type === 'google';

    Axios.post(`${apiURL}/formuser/login/${type === 'googleOneTap' ? 'google' : type}`, qs.stringify({
      token: authToken,
      checkSocialLogin: true,
      ...(initiator && { initiator }),
      ...(isGoogle && { newGoogleFlow }),
      ...(isGoogle && { redirectUri: window.location.host }),
      ...(socialLoginVerificationHash && { socialLoginHash: socialLoginVerificationHash })
    }), {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(({ data: resp }) => {
        const {
          responseCode,
          message,
          content: {
            email, imageUrl, fullName, socialLoginHash, idToken: jwtToken
          }
        } = resp;

        if (responseCode !== 200) {
          throw new Error(message);
        }

        if (socialLoginHash) {
          this.handleSocialTermsRequired({
            socialLoginHash,
            socialUserProfile: {
              email,
              imageUrl,
              fullName,
              isFormUser: true
            },
            idToken: jwtToken
          });
          return { data: false };
        }
        return { data: resp };
      })
      .then(({ data: resp }) => {
        if (resp) {
          this.handleLoginSuccess(resp);
        }
      })
      .catch(error => {
        const { response: { data: { error: message = '' } = {} } = {}, message: errorMsg = '' } = error;
        if (message === "Sorry, you can't signup from this country." || errorMsg === "Sorry, you can't signup from this country.") {
          openOFACModal(this.ofacModalRef);
        }

        if (message === 'Your account has been suspended.' || errorMsg === 'Your account has been suspended.') {
          return handleCustomNavigation('/account-suspended', '_self', true);
        }

        console.error('--Couldn\'t login', error);
        this.handleSocialErrorChange(error);
      });
  };

  regularLoginRequest = async (username, password, _appName, _recaptcha, tfaToken, tfaTokenType, _buttonNames, eaToken, rememberDevice) => {
    const { apiURL } = this.props;
    const userLoginRequest = () => {
      return Axios.post(`${apiURL}/user/login`,
        createFormData({
          username,
          password,
          rememberDevice,
          ...(tfaToken && { token: tfaToken }),
          ...(eaToken && { eaToken: eaToken }),
          ...(tfaToken && { tokenType: tfaTokenType }),
          ...(!tfaToken && { eat: '1' }) // email auth parameter
        }), {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
    };

    const formUserLoginRequest = () => {
      const { appName } = this.props;
      return Axios.post(`${apiURL}/formuser/login`, createFormData({
        email: username,
        password,
        ref_app: appName
      }), {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
    };

    const handleConnectionFailure = error => {
      throw new LoginError('Network', null, 'An error occured: Please check your internet connection', error);
    };

    const parseResponse = async response => {
      const requestURL = response?.config?.url || '';
      const loginType = /formuser/.test(requestURL) ? 'formUser' : 'user';
      return { loginType, response: response.data };
    };

    let loginResponse;
    let tfaResponse = false;
    let requestError;
    const failedResponses = [];

    const loginRequests = tfaToken ? [userLoginRequest(username, password)] : [userLoginRequest(username, password), formUserLoginRequest(username, password)];
    await Promise.all(loginRequests.map(loginPromise => loginPromise.catch(handleConnectionFailure).then(parseResponse)))
      .then(responses => {
        responses.forEach(({ response, loginType }) => {
          const { responseCode = 500, message = '' } = response;
          if (responseCode === 200) {
            tfaResponse = response?.content?.primaryMethod || false;
            loginResponse = response;
          } else {
            failedResponses.push({
              loginType,
              message
            });
          }
        });
      })
      .catch(error => {
        if (!(error instanceof LoginError)) throw error;
        const { type } = error;
        if (['Network', 'jsonParse'].includes(type)) {
          requestError = error;
        }
      });

    // if (loginResponse?.emailAuthToken) return [false, loginResponse, false, true]; // email authentication

    if (loginResponse) {
      if (tfaResponse) {
        return [false, loginResponse, true, false]; // 2fa
      }
      return [true, loginResponse];
    }

    let userRequestError;
    let formUserRequestError;
    failedResponses.forEach(({ loginType, message }) => {
      if (loginType === 'formUser') {
        formUserRequestError = { message };
      } else if (!/You're not authorized to use/.test(message)) {
        userRequestError = { message };
      }
    });

    return [false, requestError || userRequestError || formUserRequestError];
  };

  handleLogin = (username, password, recaptcha, tfaToken, tfaTokenType, eaToken, rememberDevice, isRecovery) => {
    const {
      forceUser, appName, shouldCallNavigate, buttonNames
    } = this.props;
    const {
      regularLoginRequest, handleNavigationChangeRequest, handleLoginSuccess, handleTFAResponse, handleEAResponse
    } = this;
    return handleLoginHelper({
      username,
      password,
      tfaToken,
      tfaTokenType,
      forceUser,
      regularLoginRequest,
      handleNavigationChangeRequest,
      handleLoginSuccess,
      appName,
      recaptcha,
      shouldCallNavigate,
      handleTFAResponse,
      buttonNames,
      eaToken,
      handleEAResponse,
      rememberDevice,
      isRecovery
    });
  };

  handleLoginSuccess = (response = {}) => {
    const {
      content: user,
      data: { user: userData, newUser } = {},
      responseCode
    } = response;
    const {
      forceUser,
      onUserLogin,
      shouldCallNavigate,
      baseApp
    } = this.props;
    const { actionLogger } = this.props;
    if (actionLogger) {
      actionLogger({
        target: 'loginWithEmail'
      });
    }

    if (forceUser) {
      // be in mind that social logins dont return user
      onUserLogin(user || userData || false, { newUser });
      if (baseApp === 'form-encryption') {
        this.handleNavigationChangeRequest('verifyAccount');
      }
    } else if (responseCode === 200) {
      this.isLoggedIn = true;

      const { primaryMethod = '' } = user;
      if (primaryMethod) {
        this.handle2FASocialLoginRequest(user);
        return;
      }

      if (window?.JFAppsManager?.isOpenedInPortal()) {
        window.JFAppsManager.userChanged(user);
      }

      if (shouldCallNavigate) {
        this.handleNavigationChangeRequest('epilogue');
      }

      if (window.JFFormUserHelper) {
        window.JFFormUserHelper.setUser(user);
      }

      onUserLogin(user, { newUser });
    }
  };

  handleSalesforceLoginClick = () => {
    const { onSalesforceLoginClick } = this.props;

    const salesforceLogin = initSalesforceSignin();
    if (!salesforceLogin) return;
    salesforceLogin.then(this.handleSalesforceSuccess);

    onSalesforceLoginClick();
  };

  handleGoogleLoginClick = () => {
    const { onGoogleLoginClick } = this.props;
    const client = initGoogleSignin(this.handleGoogleSuccess);
    if (client) {
      client.requestCode();
    }

    onGoogleLoginClick();
  };

  handleAppleLoginClick = () => {
    const { onAppleLoginClick } = this.props;

    const appleSignin = initAppleSignin(this.nonce);
    if (appleSignin) appleSignin.then(this.handleAppleSuccess);

    onAppleLoginClick();
  };

  handleFBLoginClick = () => {
    const { onFBLoginClick } = this.props;

    const fbLogin = initFBSignin();
    if (!fbLogin) return;
    fbLogin(this.handleFacebookSuccess, { scope: 'public_profile,email' });

    onFBLoginClick();
  };

  handleMicrosoftLoginClick = () => {
    const { onMicrosoftLoginClick } = this.props;

    const microsoftLogin = initMicrosoftSignin();
    if (!microsoftLogin) return;
    microsoftLogin.then(this.handleMicrosoftSuccess);

    onMicrosoftLoginClick();
  };

  handleNavigationChangeRequest = nextRoute => {
    const isScreenExists = this.getActiveScreen(nextRoute);
    if (!isScreenExists) return;

    const { onNavigationChange } = this.props;

    this.setState({
      activeScreenKey: nextRoute,
      thereSocialError: false
    }, () => {
      onNavigationChange(nextRoute);
    });
  };

  handleSocialErrorChange = status => {
    this.setState({
      thereSocialError: !!status,
      thereErrorMessage: status
    }, () => {
      this.clearError = setTimeout(() => {
        this.setState({
          thereSocialError: false,
          thereErrorMessage: false
        });
      }, 3000);
    });
  };

  getActiveScreen = screenKey => {
    const { additionalScreens, forceSSO } = this.props;

    const loginScreens = {
      signupOptions: SignupOptions,
      signupWithEmail: SignupWithEmail,
      loginOptions: (forceSSO ? SSOOptions : LoginOptions),
      forgotPassword: ForgotPassword,
      resetSuccess: ResetSuccess,
      twoFactorLogin: TwoFactorLogin,
      twoFactorMethods: TwoFactorMethods,
      socialInit: SocialInit,
      socialTermsStep: SocialTermsStep,
      emailAuthLogin: EmailAuthLogin,
      deleteAccount: DeleteAccountModal,
      verifyAccount: VerifyAccountModal,
      ofacModal: OFACModal,
      signupWithSocialOpts: SignupWithSocialOpts,
      recoverAccountPage: RecoverAccountPage,
      accountRecoveryViewTicket: AccountRecoveryViewTicket,
      recoverAccountNotification: RecoverAccountNotification
    };
    const possibleScreens = {
      ...loginScreens,
      ...additionalScreens
    };
    return possibleScreens[screenKey];
  };

  handleClose = () => {
    const { navigationWrapper, onClose, isReact17 } = this.props;
    onClose();
    if (isReact17 && navigationWrapper instanceof HTMLElement) {
      ReactDOM.unmountComponentAtNode(navigationWrapper);
    }

    const { activeScreenKey } = this.state;
    if (typeof window.__accountSharingLogger === 'function' && activeScreenKey === 'emailAuthLogin') {
      window.__accountSharingLogger({ action: 'click', target: 'closeButton' });
    }
  };

  handleUserLogout = async (...args) => {
    const { onUserLogout } = this.props;
    if (window?.JFAppsManager?.isOpenedInPortal()) {
      window.JFAppsManager.logUserOut();
    }
    onUserLogout(...args);
  };

  handle2FARecoverAccount = async () => {
    this.setState({
      tfaAccountRecoveryProps: {
        isErrored: false, errorMessage: '', email: '', isLoading: true, ticket: ''
      }
    });
    try {
      const { responseCode = '', message = '', content: { email = '', hasOpenTicket = false, ticket = '' } } = await send2FAAccountRecoveryEmail();
      if (responseCode === 200) {
        if (!hasOpenTicket && !ticket && email) {
          this.setState({
            tfaAccountRecoveryProps: {
              isErrored: false,
              errorMessage: '',
              email: email,
              isLoading: false,
              ticket: ''
            }
          }, () => this.handleNavigationChangeRequest('recoverAccountNotification'));
        } else if (hasOpenTicket && ticket && !email) {
          this.setState({
            tfaAccountRecoveryProps: {
              isErrored: false,
              errorMessage: '',
              email: '',
              isLoading: false,
              ticket: ticket
            }
          }, () => this.handleNavigationChangeRequest('accountRecoveryViewTicket'));
        } else {
          this.setState({
            tfaAccountRecoveryProps: {
              isErrored: true,
              errorMessage: 'An error occured. Please try again.',
              email: '',
              isLoading: false,
              ticket: ''
            }
          });
        }
      } else {
        this.setState({
          tfaAccountRecoveryProps: {
            isErrored: true,
            errorMessage: /You're not authorized to use/.test(message) ? 'The session has expired. Please login again.' : 'An error occured. Please try again.',
            email: '',
            isLoading: false,
            ticket: ''
          }
        });
      }
    } catch ({ message = '' }) {
      this.setState({
        tfaAccountRecoveryProps: {
          isErrored: true,
          errorMessage: /You're not authorized to use/.test(message) ? 'The session has expired. Please login again.' : 'An error occured. Please try again.',
          email: '',
          isLoading: false,
          ticket: ''
        }
      });
    }
  };

  handleEAResponseStateChange = () => {
    const { showEmailAuthStepAsModal } = this.props;
    const {
      lastEAResponse,
      thereSocialError,
      thereErrorMessage,
      isSocialEA
    } = this.state;

    if (showEmailAuthStepAsModal) {
      renderStandaloneEmailAuthModal({
        handleEALogin: this.handleEALogin,
        lastEAResponse,
        thereSocialError,
        thereErrorMessage,
        isSocialEA
      });
    } else {
      this.handleNavigationChangeRequest('emailAuthLogin');
    }
  };

  handleTFAResponse = (data, username, password) => {
    const { primaryMethod = '' } = data;
    this.setState({
      lastLoginCredentials: { username, password },
      lastTFAResponse: data,
      isSocialTFA: false,
      tfaMethod: primaryMethod
    });
    this.handleNavigationChangeRequest('twoFactorLogin');
  };

  handleEAResponse = (data, username, password) => {
    this.setState({
      lastLoginCredentials: { username, password },
      lastEAResponse: data,
      isSocialEA: false
    }, () => this.handleEAResponseStateChange());
  };

  handleTFALogin = async ({
    verificationCode, newType, shouldNavigate = false, rememberDevice
  } = {}) => {
    const {
      lastLoginCredentials: {
        username,
        password
      },
      lastTFAResponse: { token: { type } }
    } = this.state;
    const tfaMethod = newType || type;
    const isRecovery = tfaMethod === 'recovery';
    this.setState({ tfaMethod });
    if (newType === 'recovery' && !verificationCode) {
      this.handleNavigationChangeRequest('twoFactorLogin');
      return;
    }

    if (newType && !shouldNavigate && !verificationCode) {
      this.handleNavigationChangeRequest('twoFactorLogin');
      return;
    }
    const [loginSuccess, loginResponse] = await this.handleLogin(username, password, false, verificationCode, tfaMethod, null, rememberDevice, isRecovery);
    return [loginResponse, loginSuccess];
  };

  handleEALogin = async ({ verificationCode, isSocialEA = false } = {}) => {
    const {
      lastLoginCredentials: {
        username,
        password
      },
      lastSocialLoginType,
      lastSocialLoginToken
    } = this.state;

    if (isSocialEA) {
      this.socialLoginRequest(lastSocialLoginType, lastSocialLoginToken, null, null, verificationCode);
      return;
    }

    const [loginSuccess, loginResponse] = await this.handleLogin(username, password, false, null, null, verificationCode);
    return [loginResponse, loginSuccess];
  };

  handleSocialTFAResponse = tfaResponse => {
    this.setState({
      lastTFAResponse: tfaResponse,
      isSocialTFA: true
    },
    () => this.handleNavigationChangeRequest('twoFactorLogin'));
  };

  handleSocialEAResponse = (eaResponse, type) => {
    const { idToken } = eaResponse;
    if (type) {
      this.setState({ lastSocialLoginType: type });
    }
    this.setState({
      lastEAResponse: eaResponse,
      isSocialEA: true,
      idToken
    },
    () => this.handleEAResponseStateChange());
  };

  openLoginFlowModal = () => {
    this.setState({
      showThankyouModal: false
    });
  };

  handleLoginFlowDefaultAndThankYouModal = DefaultComponent => {
    const { showThankyouModal } = this.state;
    const { isSSO, onUserLogin } = this.props;

    return (
      showThankyouModal ? (
        <Dialog
          onOtherButtonClick={isSSO ? onUserLogin : this.openLoginFlowModal}
          isOpen={true}
          closable={false}
          oneButtonDialog={true}
          loadingButtonEnable
          wrapperClassName="isNew forCompleted"
          otherButtonText={Texts.VIEW_COMPLETED_APPROVALS}
          title={Texts.COMPLETED_TASK}
          type='success'
          isOneButtonColor="blue"
          onClose={() => undefined}
        />
      ) : (
        <div>
          {DefaultComponent}
        </div>
      )
    );
  };

  handleLoginFlowAndThankYouModal = (ComponentValue, mode) => {
    const { showThankyouModal } = this.state;
    const {
      thankYouModalOnClose, closableDialog, closableComponent, modalOptions, isSSO, onUserLogin
    } = this.props;
    const subTitles = modalOptions.subTitles ? modalOptions.subTitles : [
      Texts.COMPLETED_TASK
    ];
    return (
      showThankyouModal ? (
        <Dialog
          onOtherButtonClick={isSSO ? onUserLogin : this.openLoginFlowModal}
          isOpen={true}
          onClose={thankYouModalOnClose}
          wrapperClassName="isNew forThankYou"
          oneButtonDialog={true}
          otherButtonText={Texts.VIEW_COMPLETED_APPROVALS}
          title={Texts.THANK_YOU}
          subTitles={subTitles}
          type='success'
          isOneButtonColor="blue"
          loadingButtonEnable
          closable={closableDialog}
        />
      ) : (
        <div className="loginFlowModal jfOverlay isVisible xcl-overlay" data-mode={mode}>
          <div className="jfOverlay-modalWrapper">
            <div className="jfOverlay-modal isVisible xcl-modal" id="sacl-modal">
              {closableComponent && (
              <button
                aria-label="Close modal"
                className="xcl-close"
                onClick={this.handleClose}
                type="button"
              >
                <CloseWithCircleSVG />
              </button>
              )}
              <div className="xcl-contentWrapper jfOverlay-body">
                {ComponentValue}
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  changeActiveScreen = screenName => {
    this.setState({
      activeScreenKey: screenName
    });
  };

  changeActiveMode = mode => {
    this.setState({
      mode: mode
    });
  };

  render() {
    const {
      activeScreenKey,
      thereSocialError,
      lastTFAResponse,
      lastEAResponse,
      tfaMethod,
      isSocialTFA,
      isSocialEA,
      thereErrorMessage,
      socialUserProfile,
      isMsSignupTestEnabled,
      isMsSignupControlVariant,
      isMsSignupTestBVariant,
      isMsSignupTestCVariant,
      areSocialButtonsLoading,
      mode,
      externalErrorMessage,
      isSwsoTestVariant,
      tfaAccountRecoveryProps
    } = this.state;

    const {
      manager,
      skippable,
      onSkip,
      enforceHIPAARuleSet,
      apiURL,
      greetingMessage,
      hideGreetingMessageOnSignupOptions,
      greetingDescription,
      loginButtonText,
      signupButtonText,
      greetingMessageOnSignupWithEmail,
      user,
      signupPredefinedEmail,
      loginPredefinedEmail,
      verificationHash,
      resourceID,
      productType,
      forceLogin,
      forceUser,
      appName,
      isHIPAA,
      includeConfirm,
      showLogoOnSignupOptions,
      showLogoOnSignupWithEmail,
      actionLogger,
      loginWelcomeText,
      OtherColumnComponents,
      appleSigninEnabled,
      loginDescriptionText,
      showFormOnSignupOptions,
      buttonNames,
      socialInitData,
      shrinkConfirmHeight,
      shrinkConfirmOnMobile,
      logoSource,
      firstShowThankyouModal,
      disableEmail,
      socialLogins,
      AfterVerificationComponent,
      BeforeVerificationComponent,
      VerificationWrapper,
      usePortal,
      builderShowSlides,
      horizontalSocialButtons,
      squareSocialButtonStyle,
      readonlyEmail,
      isVerified,
      setIsVerified,
      baseApp,
      showJotformLogo,
      showSalesforceLogin,
      showEnterpriseLogin,
      enterpriseLoginEndpoint,
      hideSignUp,
      redirectURL,
      signupPredefinedName,
      unusualSignupCode,
      unusualSignupToken,
      initiator,
      forceShowBack,
      ssoLoginOptions,
      ssoNormalLoginEnabled,
      initialScreen,
      useSwsoButtons,
      isOnboardingFlow,
      isOnboardingTestVariantReversed,
      registrationType
    } = this.props;

    const ActiveComponent = this.getActiveScreen(activeScreenKey);
    const Component = (
      <ActiveComponent
        verificationHash={verificationHash}
        signupPredefinedEmail={signupPredefinedEmail}
        loginPredefinedEmail={loginPredefinedEmail}
        onSalesforceLoginClick={this.handleSalesforceLoginClick}
        onGoogleLoginClick={this.handleGoogleLoginClick}
        onFBLoginClick={this.handleFBLoginClick}
        onMicrosoftLoginClick={this.handleMicrosoftLoginClick}
        onNavigationChangeRequest={this.handleNavigationChangeRequest}
        onLoginSuccess={this.handleLoginSuccess}
        onSSOLoginSuccess={this.handleSSOLoginSuccess}
        onSSOLoginFail={this.handleSSOLoginFail}
        onLoginClick={this.handleLogin}
        onAppleLoginClick={this.handleAppleLoginClick}
        onUserLogout={this.handleUserLogout}
        handleTFAResponse={this.handleTFAResponse}
        handleTFALogin={this.handleTFALogin}
        handleEALogin={this.handleEALogin}
        horizontalSocialButtons={horizontalSocialButtons}
        squareSocialButtonStyle={squareSocialButtonStyle}
        tfaMethod={tfaMethod}
        isSocialTFA={isSocialTFA}
        isSocialEA={isSocialEA}
        lastTFAResponse={lastTFAResponse}
        lastEAResponse={lastEAResponse}
        manager={manager}
        skippable={skippable}
        onSkip={onSkip}
        enforceHIPAARuleSet={enforceHIPAARuleSet}
        apiURL={apiURL}
        isLoggedIn={this.isLoggedIn}
        user={user}
        loginButtonText={loginButtonText}
        signupButtonText={signupButtonText}
        greetingMessageOnSignupWithEmail={greetingMessageOnSignupWithEmail}
        greetingMessage={greetingMessage}
        hideGreetingMessageOnSignupOptions={hideGreetingMessageOnSignupOptions}
        productType={productType}
        resourceID={resourceID}
        isHIPAA={isHIPAA}
        initiator={initiator}
        preventBack={forceLogin}
        forceUser={forceUser}
        appName={appName}
        thereSocialError={thereSocialError}
        thereErrorMessage={thereErrorMessage}
        externalErrorMessage={externalErrorMessage}
        setExternalErrorMessage={this.setExternalErrorMessage}
        includeConfirm={includeConfirm}
        showLogoOnSignupOptions={showLogoOnSignupOptions}
        showLogoOnSignupWithEmail={showLogoOnSignupWithEmail}
        actionLogger={actionLogger}
        loginWelcomeText={loginWelcomeText}
        greetingDescription={greetingDescription}
        appleSigninEnabled={appleSigninEnabled}
        loginDescriptionText={loginDescriptionText}
        showFormOnSignupOptions={showFormOnSignupOptions}
        buttonNames={buttonNames}
        socialInitData={socialInitData}
        socialLoginRequest={this.socialLoginRequest}
        handle2FASocialLoginRequest={this.handle2FASocialLoginRequest}
        shrinkConfirmOnMobile={shrinkConfirmOnMobile}
        shrinkConfirmHeight={shrinkConfirmHeight}
        logoSource={logoSource}
        // social login terms and conditions
        socialUserProfile={socialUserProfile}
        onSocialTermsContinue={this.handleSocialTermsContinue}
        disableEmail={disableEmail}
        // A/B Test: microsoftSignup
        isMsSignupTestEnabled={isMsSignupTestEnabled}
        isMsSignupControlVariant={isMsSignupControlVariant}
        isMsSignupTestBVariant={isMsSignupTestBVariant}
        isMsSignupTestCVariant={isMsSignupTestCVariant}
        areSocialButtonsLoading={areSocialButtonsLoading}
        registerMsAbTestAction={f => f}
        ref={this.activeComponentRef}
        // delete my account
        socialLogins={socialLogins}
        handleClose={this.handleClose}
        // VerifyAccountModal
        AfterVerificationComponent={AfterVerificationComponent}
        BeforeVerificationComponent={BeforeVerificationComponent}
        VerificationWrapper={VerificationWrapper}
        isVerified={isVerified}
        setIsVerified={setIsVerified}
        baseApp={baseApp}
        ofacModalRef={this.ofacModalRef}
        changeActiveScreen={this.changeActiveScreen}
        builderShowSlides={builderShowSlides}
        readonlyEmail={readonlyEmail}
        showJotformLogo={showJotformLogo}
        showSalesforceLogin={showSalesforceLogin}
        showEnterpriseLogin={showEnterpriseLogin}
        enterpriseLoginEndpoint={enterpriseLoginEndpoint}
        hideSignUp={hideSignUp}
        ssoLoginOptions={ssoLoginOptions}
        redirectURL={redirectURL}
        signupPredefinedName={signupPredefinedName}
        isSwsoTestVariant={isSwsoTestVariant}
        forceShowBack={forceShowBack && !forceLogin && initialScreen !== 'loginOptions'}
        tfaAccountRecoveryProps={tfaAccountRecoveryProps}
        handle2FARecoverAccount={this.handle2FARecoverAccount}
        useSwsoButtons={useSwsoButtons}
        handleSocialEAResponse={this.handleSocialEAResponse}
        isOnboardingFlow={isOnboardingFlow} // A/B Test: signupOnboardingIV
        isOnboardingTestVariantReversed={isOnboardingTestVariantReversed} // A/B Test: signupOnboardingIV
        getUserSSOStatus={getUserSSOStatus}
        registrationType={registrationType}
      />
    );

    switch (mode) {
      case 'modal':
        return (
          firstShowThankyouModal ? (
            this.handleLoginFlowAndThankYouModal(Component, mode)
          ) : (
            <Portal
              usePortal={usePortal}
              ref={this.portalRef}
            >
              <div
                className={`loginFlowModal jfOverlay isVisible xcl-overlay${activeScreenKey === 'verifyAccount' ? ' verifyModal' : ''}`}
                data-mode={mode}
              >
                <div className="jfOverlay-modalWrapper">
                  <div className="jfOverlay-modal isVisible xcl-modal" id="sacl-modal">
                    <button
                      aria-label={t('Close modal')}
                      title={t('Close modal')}
                      className="xcl-close"
                      onClick={this.handleClose}
                      type="button"
                    >
                      <CloseWithCircleSVG />
                    </button>
                    <div
                      className={classNames([
                        'xcl-contentWrapper jfOverlay-body',
                        baseApp === 'form-encryption' && 'encryption-mode'
                      ])}
                    >
                      {Component}
                    </div>
                  </div>
                </div>
              </div>
            </Portal>
          )
        );
      case 'multi-column-modal':
        const allComponents = [Component, ...OtherColumnComponents];
        return (
          <div className="loginFlowModal jfOverlay isVisible xcl-overlay" data-mode={mode}>
            <div className="jfOverlay-modalWrapper">
              <div className="jfOverlay-modal isVisible xcl-modal" id="sacl-modal">
                <button
                  aria-label="Close modal"
                  className="xcl-close"
                  onClick={this.handleClose}
                  type="button"
                >
                  <CloseWithCircleSVG />
                </button>
                <div className="xcl-contentWrapper jfOverlay-body jfMultiColumnModal">
                  {allComponents.map((Column, id) => (
                    <div className="column" data-column-id={id === 0 ? 'main' : id}>
                      {Column}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );
      case 'unusual-signup':
        return (
          <UnusualSignup
            unusualSignupCode={unusualSignupCode}
            unusualSignupToken={unusualSignupToken}
            changeActiveMode={this.changeActiveMode}
          />
        );
      default:
        return (
          firstShowThankyouModal ? (
            this.handleLoginFlowDefaultAndThankYouModal(Component, mode)
          ) : (
            <div className={classNames([ssoNormalLoginEnabled && 'enterprise-login-container'])}>
              {Component}
            </div>
          )
        );
    }
  }
}

LoginFlow.propTypes = {
  user: shape(),
  apiURL: string.isRequired,
  manager: shape(),
  onClose: func,
  onUserLogout: func,
  onNavigationChange: func,
  onGoogleLoginClick: func,
  onFBLoginClick: func,
  onMicrosoftLoginClick: func,
  onAppleLoginClick: func,
  onSalesforceLoginClick: func,
  additionalScreens: shape(),
  navigationWrapper: oneOfType([
    node,
    object
  ]).isRequired,
  onUserLogin: func,
  initialScreen: string,
  skippable: bool,
  onSkip: func,
  enforceHIPAARuleSet: bool,
  mode: oneOf([
    'modal',
    'multi-column-modal',
    'unusual-signup',
    'fullScreen',
    'default'
  ]),
  greetingMessage: string,
  hideGreetingMessageOnSignupOptions: bool,
  greetingDescription: string,
  loginButtonText: string,
  loginWelcomeText: string,
  loginDescriptionText: string,
  signupPredefinedEmail: string,
  loginPredefinedEmail: string,
  signupButtonText: string,
  greetingMessageOnSignupWithEmail: string,
  verificationHash: string,
  productType: string,
  resourceID: string,
  forceLogin: bool,
  forceUser: bool,
  isSocialLoginForDeleteMyAccountFlow: bool,
  isSocialLoginForVerification: bool,
  appName: string,
  isHIPAA: bool,
  includeConfirm: bool,
  showLogoOnSignupOptions: bool,
  showLogoOnSignupWithEmail: bool,
  shouldCallNavigate: bool,
  actionLogger: func,
  OtherColumnComponents: arrayOf(element),
  appleSigninEnabled: bool,
  showFormOnSignupOptions: bool,
  enableGoogleOneTap: bool,
  shrinkConfirmOnMobile: bool,
  shrinkConfirmHeight: number,
  buttonNames: shape({
    google: string,
    googleOneTap: string,
    facebook: string,
    microsoft: string,
    apple: string,
    salesforce: string,
    email: string
  }),
  socialInitData: shape({}),
  logoSource: string,
  firstShowThankyouModal: bool,
  thankYouModalOnClose: func,
  showSocialTermsStepAsModal: bool,
  disableEmail: bool,
  horizontalSocialButtons: bool,
  squareSocialButtonStyle: bool,
  showEmailAuthStepAsModal: bool,
  closableDialog: bool,
  closableComponent: bool,
  modalOptions: shape({}),
  socialLogins: arrayOf(string),
  AfterVerificationComponent: elementType,
  BeforeVerificationComponent: elementType,
  VerificationWrapper: shape({
    Component: elementType,
    props: shape({})
  }),
  usePortal: bool,
  builderShowSlides: func,
  readonlyEmail: bool,
  isVerified: bool,
  setIsVerified: func,
  baseApp: string,
  showJotformLogo: bool,
  showSalesforceLogin: bool,
  showEnterpriseLogin: bool,
  enterpriseLoginEndpoint: string,
  hideSignUp: bool,
  redirectURL: string,
  signupPredefinedName: string,
  showUnusualSignupFlow: bool,
  unusualSignupCode: string,
  unusualSignupToken: string,
  initiator: string,
  swsoTestVariant: bool,
  forceShowBack: bool,
  ssoLoginOptions: arrayOf(shape({
    name: string,
    logoURL: string,
    configKey: string,
    idpName: string
  })),
  isSSO: bool,
  isReact17: bool,
  ssoNormalLoginEnabled: bool,
  useSwsoButtons: bool,
  isOnboardingFlow: bool,
  isOnboardingTestVariantReversed: bool,
  forceSSO: bool,
  registrationType: string
};

LoginFlow.defaultProps = {
  user: null,
  onClose: () => undefined,
  skippable: false,
  onSkip: () => undefined,
  enforceHIPAARuleSet: false,
  onUserLogin: () => undefined,
  onUserLogout: () => undefined,
  onNavigationChange: () => undefined,
  onGoogleLoginClick: () => undefined,
  onFBLoginClick: () => undefined,
  onMicrosoftLoginClick: () => undefined,
  onAppleLoginClick: () => undefined,
  onSalesforceLoginClick: () => undefined,
  thankYouModalOnClose: () => undefined,
  initialScreen: 'signupOptions',
  additionalScreens: {},
  greetingMessage: 'Welcome to Jotform',
  hideGreetingMessageOnSignupOptions: false,
  greetingDescription: '',
  loginButtonText: Texts.LOGIN_TEXT,
  loginWelcomeText: Texts.WELCOME_BACK,
  loginDescriptionText: '',
  signupButtonText: Texts.SIGN_UP,
  greetingMessageOnSignupWithEmail: Texts.SIGN_UP_EMAIL,
  signupPredefinedEmail: '',
  loginPredefinedEmail: '',
  manager: {},
  mode: 'modal',
  verificationHash: '',
  productType: 'form',
  resourceID: '',
  forceLogin: false,
  forceUser: false,
  isSocialLoginForDeleteMyAccountFlow: false,
  isSocialLoginForVerification: false,
  appName: '',
  isHIPAA: false,
  includeConfirm: false,
  showLogoOnSignupOptions: true,
  showLogoOnSignupWithEmail: false,
  shouldCallNavigate: true,
  actionLogger: null,
  OtherColumnComponents: [],
  appleSigninEnabled: false,
  showFormOnSignupOptions: false,
  enableGoogleOneTap: false,
  buttonNames: {
    google: '',
    googleOneTap: 'GoogleOnetap',
    facebook: '',
    microsoft: '',
    apple: '',
    salesforce: '',
    emailLogin: '',
    emailSignup: ''
  },
  horizontalSocialButtons: false,
  squareSocialButtonStyle: false,
  socialInitData: {},
  shrinkConfirmOnMobile: false,
  shrinkConfirmHeight: 750,
  logoSource: '',
  firstShowThankyouModal: false,
  showSocialTermsStepAsModal: false,
  disableEmail: false,
  showEmailAuthStepAsModal: false,
  closableDialog: true,
  closableComponent: true,
  modalOptions: {},
  socialLogins: [],
  AfterVerificationComponent: null,
  BeforeVerificationComponent: null,
  VerificationWrapper: null,
  usePortal: false,
  builderShowSlides: f => f,
  readonlyEmail: false,
  isVerified: false,
  setIsVerified: f => f,
  baseApp: '',
  showJotformLogo: false,
  showSalesforceLogin: false,
  showEnterpriseLogin: false,
  enterpriseLoginEndpoint: '',
  hideSignUp: false,
  redirectURL: '',
  signupPredefinedName: '',
  showUnusualSignupFlow: false,
  unusualSignupCode: '',
  unusualSignupToken: '',
  initiator: '',
  swsoTestVariant: false,
  forceShowBack: false,
  ssoLoginOptions: [],
  isSSO: false,
  isReact17: false,
  ssoNormalLoginEnabled: false,
  useSwsoButtons: false,
  isOnboardingFlow: false,
  isOnboardingTestVariantReversed: false,
  forceSSO: false,
  registrationType: ''
};
